/* eslint-disable prettier/prettier */
// JavaScript Document
(function($){

    var floatingHeaderHeight = 101; //on top , header height != header height on scroll

    var breakpoint = 768;
    var gnavMenuOpenFlg = 0;

    var _ua = (function(u){
        return {
            Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
            || u.indexOf("ipad") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
            || u.indexOf("kindle") != -1
            || u.indexOf("silk") != -1
            || u.indexOf("playbook") != -1,
            Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
            || u.indexOf("iphone") != -1
            || u.indexOf("ipod") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
            || u.indexOf("blackberry") != -1
        }
    })(window.navigator.userAgent.toLowerCase());

    //viewport set
    var viewport = function(){
        if(_ua.Mobile){
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0">');
        }
    }

    //phone to
    var phoneLink = function(){
        var $phoneLink = $('a[href*="tel:"]');
        $phoneLink.click(function(){
            if(!_ua.Mobile){
                return false;
            }
        });
        $phoneLink.css({
            cursor: 'text',
            textDecoration: 'none'
        });
    }

    //gnav
    var gnavMenuOpen = function(){
        if($('#gheader .menu').length){
            $('#gheader .menu > *').click(function(){
                if($(this).hasClass('s_animate')){
                    if($(this).hasClass('is_active')){
                        $(this).removeClass('is_active').addClass('close');
                    }else{
                        $(this).addClass('is_active').removeClass('close');
                    }
                }else{
                    $(this).toggleClass('is_active');
                }

                if(gnavMenuOpenFlg == 0){
                    $('#gnav').slideDown();
                    gnavMenuOpenFlg = 1;
                }else{
                    $('#gnav').slideUp();
                    gnavMenuOpenFlg = 0;
                }

                return false;
            });
			$('#gnav ul.page > li.accWrap > a').click(function(){
				$(this).toggleClass('is_active');
				$(this).next('.accMenu').slideToggle();
				return false;
			});
            gnavMenu();
        }
    }
    var gnavMenu = function(){
        if($('#gheader .menu').length){
            if($(window).width() < breakpoint){
                $('#gnav').hide();
            }else{
                $('#gheader .menu > *').removeClass('is_active');
                $('#gnav').removeAttr('style');
                gnavMenuOpenFlg = 0;
            }
        }
    }

    //accordion
    var accordion = function(){
        if($('.m_accWrap').length){
            $('.m_accWrap').each(function(){
                var trigger = $(this).find('.accBtn');
                var target = $(this).find('.accInner');
                $(trigger).click(function(){
                    $(this).toggleClass('is_active');
                    $(target).slideToggle(300);
                    return false;
                });
            });
        }
    }

    //masonry
    var masonry = function(){
        
        if($('.js_masonry').length){
            var selectedElement = $('.js_masonry');
            var gutter = 0;
            if($(window).width() < breakpoint){
                gutter = 16;
            } else {
                gutter = 20;
            }

            if(selectedElement.hasClass('no-gutter')){
                gutter = 0;
            }
                
            selectedElement.masonry({
                itemSelector: '.col',
                columnWidth: '.col',
                gutter:gutter,
                isFitWidth: true
            });
        }
    }

    //slider
    var slider = function(){
        if($('.js_slider').length){
            $('.js_slider ul').each(function(){
                var $slickElement = $(this);
                var $status = $slickElement.siblings('.slidePaging').find('span');
                $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $status.text(i + '/' + slick.slideCount);
                });
                $slickElement.slick({
                    'prevArrow':'<button type="button" class="slick-prev"><i class="fas fa-chevron-left" style="font-size: 7rem;" /></button>',
                    'nextArrow':'<button type="button" class="slick-next"><i class="fas fa-chevron-right" style="font-size: 7rem;" /></button>',
                    'infinite':false
                });
                $slickElement.slick('refresh'); // refresh for the slider to readjust position
            });
            $('.modal').on('shown.bs.modal', function (event) {
                var clickBotton = $(event.relatedTarget).data('clickbotton');
                $(this).find('.js_slider ul').slick('setPosition');
                $(this).find('.js_slider ul').slick('refresh'); // refresh for the slider to readjust position
                readmore();
            });
        }
    }

    //readmore
    var readmore = function (){
        if($('.js_readmore').length){
            $('.js_readmore').each(function(){
                var moreLink = $(this).data('more');
                var lessLink = $(this).data('less');
                var collapseHeight = ($(this).data('height')!='')?$(this).data('height'):0;
                $(this).find('.js_readmoreTarget').readmore({
                    speed: 300,
                    collapsedHeight: collapseHeight,
                    moreLink: '<a class="accBtn" href="#">' + moreLink + '</a>',
                    lessLink: '<a class="accBtn" href="#">' + lessLink + '</a>'
                });
            })
        }
    }

    //modal
    var modal = function(){
        $('.modal-youtube').on('show.bs.modal', function (event) {
            var youtube = $(event.relatedTarget).data('youtube');
            if(youtube !== null){
                $(this).find('iframe').attr('src',youtube);
            }
        });
        $('.modal-youtube').on('hidden.bs.modal', function (event) {
            $(this).find('iframe').attr('src','');
        });
    }

    //matchHeight 横並び要素の高さ揃え
    var matchHeight = function(){
        if($('.matchHeight').length > 0){
            $('.matchHeight').matchHeight();
        }
    }

    var contentWidth; 
    
    $(function(){
        contentWidth = $(window).innerWidth();
    });

    $(window).on('load', function(){
        viewport();
        phoneLink();
        gnavMenuOpen();
        accordion();
        masonry();
        slider();
        readmore();
        modal();
        matchHeight();
        tableVhHover();
        featurePageAnchorChecker();

        $('#scrollTop a[href*="#document"], .js_scroll').on('click', function(e) {
            var offsetTop = $($(this).attr('href')).offset().top;
            $('html, body').animate(
                    { scrollTop: offsetTop - floatingHeaderHeight },
                    400
            );
            return false;
        });
    });

    var timer = false;
    $(window).on('resize', function(){
        if(timer !== false){
            clearTimeout(timer);
        }
        timer = setTimeout(function(){
        var nowSize = $(window).innerWidth();
            if(contentWidth != nowSize){
                gnavMenu();
                contentWidth = $(window).innerWidth();
            }
        }, 50);
    });

    /// TODO : if the URL has popout window instruction
//    $(window).load(function(){
//        var getValue = getURLParameter('preview');
//        if(getValue!=null&&getValue!='') {
//            var selected = $(
//                'input[name="powerPushADocumentId"][value="'+getValue+'"],'+
//                'input[name="powerPushBDocumentId"][value="'+getValue+'"],'+
//                'input[name="catalogDocumentId"][value="'+getValue+'"]'
//            );
//            if(selected.length > 0){
//                // Note : since it might possible of repeated Catalog in same page, so better set to get first element
//                var getFirstSelection = selected.first();
//
//                setTimeout(function(){
//                    var getTarget = getFirstSelection.parent().find('.m_previewBtn > a');
//                    $(getTarget.data('target')).modal('show', getTarget);
//                },500);
//            }
//        }
//    });

    /// TODO : hover effect for table-vh-hover
    var tableVhHover = function(){
        if($('.table-vh-hover').length > 0){
            $('.table-vh-hover').each(function(){
                var hoverTable = $(this);
                // for lower spec browser
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.indexOf("msie") != -1){
                    $('.rt-td, .rt-th',hoverTable).hover(function(){
                        var index = $(this).index();
                        if(index != 0){
                            $('.rt-td:nth-child('+(index+1)+'), .rt-th:nth-child('+(index+1)+')',hoverTable).addClass('hover');
                        }
                    }, function() {		
                        $('.rt-th, .rt-td',hoverTable).removeClass('hover');
                    });
                } else {
                    hoverTable.on({
                        mouseenter: function () {
                            var index = $(this).index();
                            if(index != 0){
                                $('.rt-td:nth-child('+(index+1)+'), .rt-th:nth-child('+(index+1)+')',hoverTable).addClass('hover');
                            }
                            $(this).parent().find('.rt-td').addClass('hover');
                        },
                        mouseleave: function () {
                            //stuff to do on mouse leave
                            $('.rt-td, .rt-th',hoverTable).removeClass('hover');
                        }
                    }, '.rt-td, .rt-th');
                }
            });
        }
    }

    var featurePageAnchorChecker = function() {
        // only feature page w/ anchor & hash in URL
        if($('.feature-anchor').length > 0 && !!window.location.hash) {
            var retryCount = 0;
            var currentHash = window.location.hash;
            var currentOffsetTop = $(currentHash).get(0).offsetTop;
            $('html, body').scrollTop(currentOffsetTop - floatingHeaderHeight);
            var scrollCheckTimer = setInterval(function () {
                var latestOffsetTop = $(currentHash).get(0).offsetTop;
                if(latestOffsetTop != currentOffsetTop) {
                    currentOffsetTop = latestOffsetTop;
                    retryCount = 0;
                    $('html, body').scrollTop(currentOffsetTop - floatingHeaderHeight);
                } else {
                    // kill interval timer if 3 same pos is same
                    // 5 second check
                    if(retryCount > 5) {
                        if(scrollCheckTimer) {
                            clearTimeout(scrollCheckTimer);
                            scrollCheckTimer = null;
                        }
                    } else {
                        retryCount++;
                    }
                }
            }, 1000);
            $(window).on('mousewheel', function(){ 
                if(scrollCheckTimer) {
                    clearTimeout(scrollCheckTimer);
                    scrollCheckTimer = null;
                }
            });
        }
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }
})(jQuery);